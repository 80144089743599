import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "@components/seo"
import Layout from "@components/layout"
import Footer from "@components/Footer/Footer"
import SocialLinks from "@components/Nav/SocialLinks"
import RandomTestimonial from "@components/Quotes/RandomTestimonial"
import Lantern from "@static/icons/lantern.svg"
import Tool from "@components/Nav/Tool"

export const query = graphql`
  query ServicesPageQuery($id: Int) {
    strapiServicespage(strapiId: { eq: $id }) {
      strapiId
      title
      subtitle
      description
      moredetails
    }
    allStrapiService(sort: {fields: [order], order: ASC}) {
      edges {
        node {
          id
          title
          description
          slug
          order
          tools {
            name
            url
            logoURL
          }
        }
      }
    }
  }
`

const ServicesPage =  ({ data }) => {
  const servicesPage = data.strapiServicespage
  const servicesList = data.allStrapiService
  const title = servicesPage.title ? servicesPage.title : ""
  const description = servicesPage.description ? servicesPage.description : ""
  const subtitle = servicesPage.subtitle ? servicesPage.subtitle : ""
  const moreDetails = servicesPage.moredetails

  return (
    <Layout>
      <main className="grid-content">
        <div className="grid-content-inner">
          <div id="services" className="grid-container page">
            <SEO
              title={title}
              description={description}
            />
            <Lantern />
            <h1>{title}</h1>
            <h2>{subtitle}</h2>

            <RandomTestimonial />

            <section className="services-section grid-block align-center">
              {servicesList.edges.map(item => {
                const service = item.node
                const serviceID = service.id
                const serviceTitle = service.title ? service.title : ''
                const serviceDescription = service.description ? service.description : ''
                const serviceSlug = service.slug ? service.slug : ''
                const serviceTools = service.tools ? service.tools : null

                let serviceTitleBlock
                let serviceMoreDetailsBlock

                if (moreDetails) {
                  serviceTitleBlock = (
                    <AniLink
                      to={serviceSlug}
                      title={serviceTitle}
                      fade
                      duration={1}
                    >
                      <h3>{serviceTitle}</h3>
                    </AniLink>
                  )

                  serviceMoreDetailsBlock = (
                    <AniLink
                      to={serviceSlug}
                      title={serviceTitle}
                      fade
                      duration={1}
                    >
                      <p className="more-details">More details...</p>
                    </AniLink>
                  )
                } else {
                  serviceTitleBlock = (
                    <h3>{serviceTitle}</h3>
                  )

                  serviceMoreDetailsBlock = null
                }


                let toolkit = []

                if (serviceTools) {
                  serviceTools.map(item => {
                    const tool = (
                      <Tool
                        key={item.name}
                        name={item.name}
                        url={item.url}
                        logoURL={item.logoURL}
                      />
                    )
                    // console.log('tool: ', tool)
                    toolkit.push(tool)
                  })
                }

                return (
                  <>
                  <div className="grid-block small-up-1 medium-up-1 large-up-2" key={serviceID}>
                    <div className="service-title small-up-1 medium-up-2 large-up-2">
                      {serviceTitleBlock}
                    </div>
                    <div className="service-description small-up-1 medium-up-2 large-up-2">
                      <ReactMarkdown children={serviceDescription} />
                      {serviceMoreDetailsBlock}
                    </div>
                  </div>
                  {toolkit &&
                    <div className="toolkit">
                      <h4>Toolkit:</h4>
                      <div className="tools-wrapper">
                        {toolkit}
                      </div>
                    </div>
                  }
                  </>
                )
              })}
            </section>
            <SocialLinks />
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default ServicesPage